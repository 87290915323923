// Media
let mediaUrlTemp
let viewUrlTemp
if (process.env.NODE_ENV !== 'production') {
    // mediaUrlTemp = '//localhost:8887/'
    // mediaUrlTemp = 'https://media.justtheweb.io/'
    mediaUrlTemp = 'https://klhkpm8h.tinifycdn.com/'
    viewUrlTemp = 'http://localhost:8100'
} else {
    // mediaUrlTemp = 'https://media.justtheweb.io/'
    mediaUrlTemp = 'https://klhkpm8h.tinifycdn.com/'
    viewUrlTemp = 'https://app.justtheweb.io'
}

// View
export const viewUrlSite = viewUrlTemp

// Goolgle
export const GOOGLE_CLIENT_ID_SITE = process.env.NODE_ENV !== 'production' ? '430535249587-7b5imj3prp5ccsohvv4kgot1fa9fmuli.apps.googleusercontent.com' : '430535249587-kl3t2ga5ns9gmlmpr2n14qnp4koi8kbm.apps.googleusercontent.com'

// Leaflet
export const leaflet_attribution_mapbox_site = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
export const leaflet_url_mapbox_dark_site = 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXVwb3VyYmF1IiwiYSI6ImNrZjloendkcjBtNGcyeXFzbGs4Y21uejYifQ.G_99fsa5cAAm_hQ54NUNdg'
export const leaflet_url_mapbox_light_site = 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXVwb3VyYmF1IiwiYSI6ImNrZjloendkcjBtNGcyeXFzbGs4Y21uejYifQ.G_99fsa5cAAm_hQ54NUNdg'
export const leaflet_url_mapbox_outdoors_site = 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXVwb3VyYmF1IiwiYSI6ImNrZjloendkcjBtNGcyeXFzbGs4Y21uejYifQ.G_99fsa5cAAm_hQ54NUNdg'
export const leaflet_url_mapbox_satellite_site = 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXVwb3VyYmF1IiwiYSI6ImNrZjloendkcjBtNGcyeXFzbGs4Y21uejYifQ.G_99fsa5cAAm_hQ54NUNdg'
export const leaflet_url_mapbox_streets_site = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXVwb3VyYmF1IiwiYSI6ImNrZjloendkcjBtNGcyeXFzbGs4Y21uejYifQ.G_99fsa5cAAm_hQ54NUNdg'

// Media
export const media_url_platform_site = `${mediaUrlTemp}main/platform/`
export const media_url_social_network_site = `${mediaUrlTemp}main/social_network/`
export const media_url_site = mediaUrlTemp
export const media_version_site = '?v32'

export const app_version_site = '2025-02-09-14-08'
